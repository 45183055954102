<template>
  <div>
    <NavbarMain />

    <div class="contenedor">
      <div class="filter-regbio">
        <div class="grid">
          <div class="field col-12">
            <Dropdown
              class="w-100"
              v-model="registrobio.fincafiltro"
              :options="fincascombo"
              optionLabel="fin_nombre"
              placeholder="Filtrar busqueda x finca"
              @change="onChangeFilterFinca($event)"
            />
          </div>

          <div class="field col-12">
            <div class="p-inputgroup mr-4">
              <InputText
                placeholder="Buscar x nombre de especie"
                v-model="registrobio.filtro"
                @keyup.enter="filtrarregistrosbio"
              />

              <Button
                icon="pi pi-search"
                class="p-button-success"
                
                @click="filtrarregistrosbio"
              />
            </div>
          </div>

          <div class="field col-12">
            <Button
              class="p-button-success w-100"
              icon="pi pi-plus"
              label="Nuevo registro"
              @click="nuevoRegistroBiodiversidad()"
            />
          </div>
        </div>

        <div class="graph">
          <Dropdown
            v-model="anioselected"
            :options="kpianios"
            optionLabel="anios"
            placeholder="Seleccionar año estadística Bio"
            class="mb-2 w-90"
            @change="onChangeAnioReport($event)"
          />
          <h5>Registros de Biodiversidad por mes</h5>
          <div class="graph">
            <Chart
              type="bar"
              :data="basicData"
              :options="basicOptions"
              v-show="!cargaskeletonkpiregistros"
              :responsive="true"
            />
          </div>
        </div>
      </div>

      <div class="panel-bio">
        <div class="result-data">
          <div
            class="result-query-bio"
            v-for="registro in registrosbiodiversidad"
            :key="registro.reg_id"
            @click="goToRegistroBio(registro)"
          >
            <!-- <img class="result-multimedia" src="../assets/logo.png" alt="" /> -->
            <img
              v-if="registro.multimedia[0].regmul_id != undefined"
              class="result-multimedia"
              :src="registro.multimedia[0].img"
              :alt="registro.multimedia[0].regmul_nombre"
            />
            <img
              v-else
              class="result-multimedia"
              :src="registro.multimedia.img"
              :alt="registro.multimedia.regmul_nombre"
            />

            <h5 class="tittle">{{ registro.especie.esp_especie }}</h5>
            <div class="details-registro">
              <i class="pi pi-map-marker" style="font-size: 2rem"></i>
              <h5>{{ registro.finca.fin_nombre }}</h5>
              <Chip :label="registro.reg_fecha" />
               
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="gallery console"
      :style="{ display: showGallery ? 'block' : 'none' }"
    >
      <div
        class="article console"
        v-for="imagen in images"
        :key="imagen.regmul_id"
      >
        <img
          class="multimedia console"
          :src="imagen.img"
          :alt="imagen.regmul_nombre"
        />
        <b-link class="console" :href="imagen.img" target="_blank">{{
          imagen.regmul_nombre
        }}</b-link>
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary button-trash-gallery console"
          @click="listarEliminarArchivo(imagen)"
        />
      </div>

      <div
        class="article console"
        v-for="video in videos"
        :key="video.regmul_id"
      >
        <b-embed
          class="multimedia"
          type="iframe"
          aspect="16by9"
          :src="video.source"
          allowfullscreen
        ></b-embed>
      </div>

      <div v-show="cargaskeletonmultimedia">
        <div class="custom-skeleton p-4">
          <Skeleton width="100%" height="150px"></Skeleton>
          <div class="flex justify-content-center mt-3">
            <Skeleton width="4rem" height="2rem"></Skeleton>
            <Skeleton width="4rem" height="2rem"></Skeleton>
          </div>
        </div>

        <div class="custom-skeleton p-4">
          <Skeleton width="100%" height="150px"></Skeleton>
          <div class="flex justify-content-center mt-3">
            <Skeleton width="4rem" height="2rem"></Skeleton>
            <Skeleton width="4rem" height="2rem"></Skeleton>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>

    <ConfirmDialog></ConfirmDialog>

    <Dialog
      header="Header"
      :visible.sync="showNewModalRegistroBio"
      position="center"
      :modal="true"
      :containerStyle="{ width: '50vw' }"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>Registro de Biodiversidad</h4>
      </template>

      <form class="grid">
        <div class="col-12" v-show="!registrobio.nuevo">
          <div class="p-inputgroup">
            <label>Especie</label>
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <AutoComplete
              class="font-italic"
              id="selectedEspecie"
              placeholder="Digite especie a buscar: Caiman Crocodilus Fucus "
              v-model="selectedEspecie"
              :suggestions="filteredEspecies"
              @complete="buscarEspecie($event)"
              field="esp_especie"
            />
          </div>
        </div>

        <div class="col-12" v-show="!registrobio.nuevo">
          <div class="p-inputgroup">
            <label>Finca</label>
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <Dropdown
              v-model="registrobio.reg_finca"
              :options="fincascombo"
              optionLabel="fin_nombre"
              placeholder="Seleccionar una finca"
              @change="onChangeFinca($event)"
            />
          </div>
        </div>

        <div class="col-12" v-show="!registrobio.nuevo">
          <div class="p-inputgroup">
            <label>Área de cobertura</label>
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <Dropdown
              v-model="registrobio.reg_cobertura"
              :options="coberturascombo"
              optionLabel="cob_detalle"
              placeholder="Seleccionar una cobertura"
            />
          </div>
        </div>

        <div class="col-12" v-show="!registrobio.nuevo">
          <div class="p-inputgroup">
            <label>Fecha de registro</label>
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <Calendar
              id="icon"
              placeholder="Fecha de registro: 2022-01-01"
              :maxDate="today"
              v-model="registrobio.reg_fecha"
              :showIcon="true"
              dateFormat="yy-mm-dd"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-12" v-show="!registrobio.nuevo">
          <div class="p-inputgroup">
            <label>Hora de registro</label>
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <Calendar
              id="time12"
              v-model="registrobio.reg_hora"
              placeholder="Hora de registro: 15:45:00"
              :readonlyInput="true"
              :timeOnly="true"
              hourFormat="24"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-12" v-show="!registrobio.nuevo">
          <div class="p-inputgroup">
            <label>Latitud</label>
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <InputText
              placeholder="Latitud: 11.106014"
              v-model="registrobio.reg_latitud"
            />
          </div>
        </div>

        <div class="col-12" v-show="!registrobio.nuevo">
          <div class="p-inputgroup">
            <label>Longitud</label>
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <InputText
              placeholder="Longitud: -74.1217839"
              v-model="registrobio.reg_longitud"
            />
          </div>
        </div>

        <div class="col-12" v-show="!registrobio.nuevo">
          <div class="p-inputgroup">
            <label>Altura (mts)</label>
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <InputText
              placeholder="Altura SNM: 665"
              v-model="registrobio.reg_asnm"
            />
          </div>
        </div>

        <div class="col-12" v-show="!registrobio.nuevo">
          <div class="p-inputgroup">
            <label>Tipo de Observación</label>
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <Dropdown
              v-model="registrobio.reg_tipoobservacion"
              :options="tiposobservacionescombo"
              optionLabel="tpo_nombre"
              placeholder="Seleccionar observación"
            />
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <textarea
              class="form-control"
              v-model="registrobio.reg_observaciones"
              rows="3"
              placeholder="Observaciones generales"
            ></textarea>
          </div>
        </div>

        <div class="col-12" v-show="registrobio.viewlink">
          <div class="p-inputgroup">
            <b-link :href="registrobio.linkgoogle" target="_blank"
              >Ver Mapa</b-link
            >
          </div>
        </div>

        <div class="col-12">
          <div class="p-inputgroup">
            <input
              type="file"
              class="form-control-file"
              id="file_registrobio"
              ref="bio_files"
              @change="onFileChange"
              multiple
            />
          </div>
        </div>
      </form>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="registrobio.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="registrobio.boton"
          :disabled="registrobio.activo"
          icon="pi pi-check"
          autofocus
          @click="listaraccionbio"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import NavbarMain from "@/components/NavbarMain.vue";
import * as moment from "moment";

export default {
  name: "Registrobio",
  data() {
    return {
      registrobio: {
        reg_id: 0,
        reg_latitud: null,
        reg_longitud: null,
        reg_asnm: null,
        reg_fecha: "",
        reg_hora: "",
        reg_tipoobservacion: null,
        reg_especie: null,
        reg_finca: null,
        reg_cobertura: null,
        reg_observaciones: "",

        titulomodal: "",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        fincafiltro: null,
        wait: false,
        activo: false,
        viewlink: false,
        linkgoogle: "",
      },

      showGallery: false,
      showNewModalRegistroBio: false,

      selectedEspecie: null,
      filteredEspecies: [],

      images: [],
      videos: [],

      skeletons: this.global.skeletons,

      meses: this.global.meses,
      kpiregistroscrudos: [],
      kpiregistrosdepurados: [],
      labels: [],
      data: [],
      kpianios: [],
      anioselected: null,

      today: new Date(this.global.today),

      filtrandoEspecies: null,

      cargaskeletonregistrosbio: false,
      cargaskeletonmultimedia: false,
      showmodalregistrobio: false,

      registrosbiodiversidad: [],
      especiescombo: [],
      fincascombo: [],
      tiposobservacionescombo: [],
      fincascompletas: [],
      coberturascombo: [],

      selectedFiles: [],
      sizeSelectedFiles: 0,

      cargaskeletonkpiregistros: false,

      basicData: {
        labels: this.labels,
        datasets: [
          {
            label: "Cantidad de individuos registrados",
            data: this.data,
            fill: false,
            borderColor: "#42A5F5",
            tension: 0.4,
          },
        ],
      },

      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
    };
  },
  components: {
    Toast,
    NavbarMain,
    ConfirmDialog,
  },

  methods: {
    onChangeAnioReport(event) {
      this.anioselected = event.value.anios;
      this.getkpi();
    },

    onChangeFilterFinca(event) {
      this.registrobio.fincafiltro.fin_id = event.value.fin_id;
      this.filtrarregistrosbio();
    },

    onChangeFinca(event) {
      var object = this.fincascompletas.find(
        (element) => element.fin_id === event.value.fin_id
      );
      this.registrobio.reg_asnm = object.fin_altura;
      this.registrobio.reg_latitud = object.fin_latitud;
      this.registrobio.reg_longitud = object.fin_longitud;
    },

    getespecies: async function () {
      try {
        this.cargaskeletonregistrosbio = true;
        let self = this;
        await this.axios
          .post("especies.php?dato=getespeciescombo")
          .then(function (response) {
            if (response.status === 200) {
              self.especiescombo = response.data;
            } else {
              self.mensajeErr("Error al cargar las especies");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosbio = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    registrarnuevabiodiversidad: async function () {
      try {
        var mensaje = this.validarRegistrarBio;

        if (mensaje === "") {
          this.registrobio.boton = " Guardando... ";
          this.registrobio.wait = true;
          this.registrobio.activo = true;

          let formData = new FormData();
          var nombresLargos = false;

          for (var i = 0; i < this.sizeSelectedFiles; i++) {
            formData.append("file[]", this.selectedFiles[i]);
            if (this.selectedFiles[i].name.length > 45) {
              nombresLargos = true;
            }
          }

          let day = this.registrobio.reg_fecha.getDate();
          let month = this.registrobio.reg_fecha.getMonth() + 1;
          let year = this.registrobio.reg_fecha.getFullYear();

          let hour = this.registrobio.reg_hora.getHours();
          let minutes = this.registrobio.reg_hora.getMinutes();
          let milliseconds = "00";

          let fecha =
            year +
            "-" +
            (month < 10 ? "0" + month : month) +
            "-" +
            (day < 10 ? "0" + day : day);
          let time = hour + ":" + minutes + ":" + milliseconds;

          formData.append("reg_latitud", this.registrobio.reg_latitud);
          formData.append("reg_longitud", this.registrobio.reg_longitud);
          formData.append("reg_asnm", this.registrobio.reg_asnm);
          formData.append("reg_fecha", this.registrobio.reg_fecha);

          formData.append("reg_fecha", fecha);
          formData.append("reg_hora", time);
          formData.append(
            "reg_tipoobservacion",
            this.registrobio.reg_tipoobservacion.tpo_id
          );
          formData.append("reg_especie", this.selectedEspecie.esp_id);
          formData.append("reg_finca", this.registrobio.reg_finca.fin_id);
          formData.append(
            "reg_cobertura",
            this.registrobio.reg_cobertura.cob_id
          );
          formData.append(
            "reg_observaciones",
            this.registrobio.reg_observaciones
          );

          if (!nombresLargos) {
            await this.axios({
              method: "post",
              url: "biodiversidad.php?dato=nuevoregistrobio",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.includes(this.global.transaccionOK)) {
                    this.mensajeOK(response.data);
                    this.limpiarCamposNuevoRegistro();
                    this.filtrarregistrosbio();
                  } else if (this.global.buscarapierror(response.data) !== "") {
                    let msj_user = this.global.buscarapierror(response.data);
                    this.mensajeWar(msj_user);
                  } else {
                    this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                  }
                } else {
                  this.global.mensajeErr(this.global.respuestaInvalida);
                }
              })
              .catch((error) => {
                this.$utilidad.printConsole(error);
              })
              .finally(() => {
                this.registrobio.boton = " Guardar ";
                this.registrobio.wait = false;
                this.registrobio.activo = false;
              });
          } else {
            this.mensajeWar(
              "El nombre del archivo adjunto es muy largo, se permiten máximo 45 caracteres"
            );
            this.registrobio.boton = " Guardar ";
            this.registrobio.wait = false;
          }
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    editarregistrobiodiversidad: async function () {
      try {
        var mensaje = this.validarRegistrarBio;

        if (mensaje === "") {
          this.registrobio.boton = " Editando... ";
          this.registrobio.wait = true;
          this.registrobio.activo = true;

          let formData = new FormData();
          var nombresLargos = false;

          for (var i = 0; i < this.sizeSelectedFiles; i++) {
            formData.append("file[]", this.selectedFiles[i]);
            if (this.selectedFiles[i].name.length > 45) {
              nombresLargos = true;
            }
          }

          let day = this.registrobio.reg_fecha.getDate();
          let month = this.registrobio.reg_fecha.getMonth() + 1;
          let year = this.registrobio.reg_fecha.getFullYear();

          let hour = this.registrobio.reg_hora.getHours();
          let minutes = this.registrobio.reg_hora.getMinutes();
          let milliseconds = "00";

          let fecha =
            year +
            "-" +
            (month < 10 ? "0" + month : month) +
            "-" +
            (day < 10 ? "0" + day : day);
          let time = hour + ":" + minutes + ":" + milliseconds;

          formData.append("reg_latitud", this.registrobio.reg_latitud);
          formData.append("reg_longitud", this.registrobio.reg_longitud);
          formData.append("reg_asnm", this.registrobio.reg_asnm);
          formData.append("reg_fecha", this.registrobio.reg_fecha);

          formData.append("reg_fecha", fecha);
          formData.append("reg_hora", time);
          formData.append(
            "reg_tipoobservacion",
            this.registrobio.reg_tipoobservacion.tpo_id
          );

          formData.append("reg_especie", this.selectedEspecie.esp_id);
          formData.append("reg_finca", this.registrobio.reg_finca.fin_id);
          formData.append(
            "reg_cobertura",
            this.registrobio.reg_cobertura.cob_id
          );

          formData.append(
            "reg_observaciones",
            this.registrobio.reg_observaciones
          );

          formData.append("reg_id", this.registrobio.reg_id);

          if (!nombresLargos) {
            await this.axios({
              method: "post",
              url: "biodiversidad.php?dato=editarregistrobio",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                  if (response.data.includes(this.global.transaccionOK)) {
                    this.mensajeOK(response.data);
                    this.limpiarCamposNuevoRegistro();
                    this.filtrarregistrosbio();
                  } else if (this.global.buscarapierror(response.data) !== "") {
                    let msj_user = this.global.buscarapierror(response.data);
                    this.mensajeWar(msj_user);
                  } else {
                    this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                  }
                } else {
                  this.global.mensajeErr(this.global.respuestaInvalida);
                }
              })
              .catch((error) => {
                this.$utilidad.printConsole(error);
              })
              .finally(() => {
                this.registrobio.boton = " Guardar ";
                this.registrobio.wait = false;
                this.registrobio.activo = false;
              });
          } else {
            this.mensajeWar(
              "El nombre del archivo adjunto es muy largo, se permiten máximo 45 caracteres"
            );
            this.registrobio.boton = " Guardar ";
            this.registrobio.wait = false;
          }
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    filtrarregistrosbio: async function () {
      try {
        this.cargaskeletonregistrosbio = true;
        let self = this;

        var apirest = "biodiversidad.php?dato=getregistrosbio";

        var parametros = {
          especie: this.registrobio.filtro,
        };

        if (this.registrobio.fincafiltro !== null) {
          apirest = "biodiversidad.php?dato=getregistrosbioxfinca";
          parametros = {
            especie: this.registrobio.filtro,
            finca: this.registrobio.fincafiltro.fin_id,
          };
        }

        await this.axios
          .post(apirest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.registrosbiodiversidad = response.data;
              if (self.registrosbiodiversidad.length === 0) {
                self.mensajeWar("No se encontraron registros de biodiversidad");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosbio = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getfincas: async function () {
      try {
        this.cargaskeletonregistrosbio = true;
        let self = this;
        await this.axios
          .post("fincas.php?dato=getfincascombo")
          .then(function (response) {
            if (response.status === 200) {
              self.fincascombo = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosbio = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposobservaciones: async function () {
      try {
        this.cargaskeletonregistrosbio = true;
        let self = this;
        await this.axios
          .post("tipoobservacion.php?dato=gettiposobservacionescombo")
          .then(function (response) {
            if (response.status === 200) {
              self.tiposobservacionescombo = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosbio = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getfincascomplete: async function () {
      try {
        this.cargaskeletonregistrosbio = true;
        let self = this;
        await this.axios
          .post("fincas.php?dato=getfincascombocomplete")
          .then(function (response) {
            if (response.status === 200) {
              self.fincascompletas = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosbio = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getcoberturas: async function () {
      try {
        this.cargaskeletonregistrosbio = true;
        let self = this;
        await this.axios
          .post("biodiversidad.php?dato=getcoberturasbio")
          .then(function (response) {
            if (response.status === 200) {
              self.coberturascombo = response.data;
            } else {
              self.mensajeErr("Error al cargar las coberturas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosbio = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getkpi: async function () {
      try {
        this.cargaskeletonkpiregistros = true;
        let self = this;

        var parametros = {
          year: this.anioselected,
        };

        await this.axios
          .post(
            "biodiversidad.php?dato=getkpiregistrosbio",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.kpiregistroscrudos = response.data;

              self.data = [];
              self.labels = [];
              self.kpiregistrosdepurados = [];

              for (var registro in self.kpiregistroscrudos) {
                var val = self.meses.find(
                  (m) => m.numero === self.kpiregistroscrudos[registro].mesnum
                );

                self.data.push(self.kpiregistroscrudos[registro].cantidad);
                self.labels.push(val.nombre);

                self.kpiregistrosdepurados.push({
                  mes: val.nombre,
                  cantidad: self.kpiregistroscrudos[registro].cantidad,
                });
              }

              self.basicData = {
                labels: self.labels,
                datasets: [
                  {
                    label: "Cantidad de especies",
                    data: self.data,
                    fill: false,
                    borderColor: "#42A5F5",
                    backgroundColor: "#42A5F5",
                    tension: 0.4,
                  },
                ],
              };
            } else {
              self.mensajeErr("Error al cargar KPIs biodiversidad");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonkpiregistros = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getanios: async function () {
      try {
        this.cargaskeletonkpiregistros = true;
        let self = this;
        await this.axios
          .post("biodiversidad.php?dato=getanioskpibio")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonkpiregistros = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.registrobio = {
        reg_id: 0,
        reg_latitud: null,
        reg_longitud: null,
        reg_asnm: null,
        reg_fecha: "",
        reg_hora: "",
        reg_tipoobservacion: null,
        reg_especie: null,
        reg_finca: null,
        reg_cobertura: null,
        reg_observaciones: "",
        titulomodal: "",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        fincafiltro: null,
        wait: false,
        activo: false,
      };

      this.selectedEspecie = null;
      this.selectedFiles = [];
      this.sizeSelectedFiles = 0;
      this.$refs.bio_files.value = null;

      this.images = [];
      this.videos = [];
    },

    nuevoRegistroBiodiversidad: function () {
      this.registrobio = {
        reg_id: 0,
        reg_latitud: null,
        reg_longitud: null,
        reg_asnm: null,
        reg_fecha: "",
        reg_hora: "",
        reg_tipoobservacion: null,
        reg_especie: null,
        reg_finca: null,
        reg_cobertura: null,
        reg_observaciones: "",
        titulomodal: "",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        fincafiltro: null,
        wait: false,
        activo: false,
      };

      this.selectedEspecie = null;
      this.selectedFiles = [];
      this.sizeSelectedFiles = 0;
      //this.$refs.bio_files.value = null;

      this.images = [];
      this.videos = [];

      this.showNewModalRegistroBio = true;
    },

    goToRegistroBio: function (registro) {
      alert(registro.reg_id);
    },

    openmodalregistrobio: function () {
      this.registrobio = {
        reg_id: 0,
        reg_latitud: 0.0,
        reg_longitud: 0.0,
        reg_asnm: 0,
        reg_fecha: "",
        reg_hora: "",
        reg_tipoobservacion: 0,
        reg_especie: 0,
        reg_finca: 0,
        reg_cobertura: 0,
        reg_observaciones: "",

        titulomodal: "Nuevo registro",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        wait: false,
        activo: false,
        filtro: "",
        fincafiltro: null,
      };

      this.showmodalregistrobio = true;
    },

    listarEliminarArchivo: function (data) {
      this.$confirm.require({
        message: "Estas seguro que deseas eliminar la imagen?",
        header: "Alerta",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.elminarArchivo(data.regmul_id);
        },
        reject: () => {
          this.mensajeWar("No se eliminaron archivos");
        },
      });
    },

    elminarArchivo: async function (regmul_id) {
      try {
        this.cargaskeletonmultimedia = true;
        let self = this;

        var parametros = {
          regmul_id: regmul_id,
        };

        await this.axios
          .post(
            "biodiversidad.php?dato=eliminararchivobio",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.mensajeOK(response.data);
              self.limpiarCamposNuevoRegistro();
            } else {
              self.mensajeErr("Error al eliminar archivo");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonmultimedia = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    listarmodalregistrobio: async function (data) {
      this.showGallery = true;

      this.images = [];
      this.videos = [];

      let fechaparcial = data.reg_fecha + " " + data.reg_hora;
      let fecha = new Date(fechaparcial);
      this.registrobio = {
        titulomodal: "Nuevo registro de biodiversidad",
        boton: "Editar",
        accion: 1,
        nuevo: false,
        activo: false,
        filtro: "",
        fincafiltro: null,
        wait: false,

        reg_id: data.reg_id,
        reg_latitud: data.reg_latitud,
        reg_longitud: data.reg_longitud,
        reg_asnm: data.reg_asnm,
        reg_fecha: fecha,
        reg_hora: new Date(fechaparcial),

        reg_observaciones: data.reg_observaciones,

        viewlink: true,
        linkgoogle:
          "https://www.google.com/maps/@" +
          data.reg_latitud +
          "," +
          data.reg_longitud +
          ",15z",

        reg_especie: this.especiescombo.find(
          (p) => p.esp_id === data.especie.esp_id
        ),

        reg_tipoobservacion: this.tiposobservacionescombo.find(
          (p) => p.tpo_id === data.tipoobservacion.tpo_id
        ),

        reg_finca: this.fincascombo.find((p) => p.fin_id === data.finca.fin_id),

        reg_cobertura: this.coberturascombo.find(
          (p) => p.cob_id === data.cobertura.cob_id
        ),
      };
      this.selectedEspecie = {
        esp_id: data.especie.esp_id,
        esp_especie: data.especie.esp_especie,
      };

      try {
        this.cargaskeletonmultimedia = true;
        let self = this;

        var parametros = {
          reg_id: data.reg_id,
        };

        await this.axios
          .post(
            "biodiversidad.php?dato=getmultimediaxregistrosbio",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.images = response.data.filter(
                (multimedia) =>
                  multimedia.regmul_tipoarchivo === self.global.TYPE_FILE_IMAGE
              );
              self.videos = response.data.filter(
                (multimedia) =>
                  multimedia.regmul_tipoarchivo === self.global.TYPE_FILE_VIDEO
              );
              if (self.images.length === 0 && self.videos.length === 0) {
                self.mensajeWar("No se encontraron registros multimedia");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonmultimedia = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    listaraccionbio: function () {
      if (this.registrobio.nuevo) {
        this.registrarnuevabiodiversidad();
      } else {
        this.editarregistrobiodiversidad();
      }
    },

    buscarEspecie(event) {
      if (event.query.length > 3) {
        this.filteredEspecies = this.especiescombo.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(event.query.toLowerCase())
          )
        );
      }
    },

    onFileChange(event) {
      //console.log(event);
      this.sizeSelectedFiles = event.target.files.length;
      this.selectedFiles = event.target.files;
    },
  },

  computed: {
    validarRegistrarBio() {
      var mensajeAlerta = "";
      if (this.selectedEspecie === null) {
        mensajeAlerta =
          "Debe seleccionar una especie existente en la base de datos";
      } else if (this.selectedEspecie.esp_id === undefined) {
        mensajeAlerta =
          "Debe seleccionar una especie existente en la base de datos";
      } else if (this.registrobio.reg_finca === null) {
        mensajeAlerta = " Debe seleccionar una finca ";
      } else if (this.registrobio.reg_cobertura === null) {
        mensajeAlerta = "Debe seleccionar un area de cobertura";
      } else if (
        this.registrobio.reg_latitud === null ||
        Number.isNaN(Number(this.registrobio.reg_latitud)) ||
        this.registrobio.reg_latitud > 180 ||
        this.registrobio.reg_latitud < -180 ||
        this.registrobio.reg_latitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Latitud válido ej: 11.144433 separando decimales con punto (.) ";
      } else if (
        this.registrobio.reg_longitud === null ||
        Number.isNaN(Number(this.registrobio.reg_longitud)) ||
        this.registrobio.reg_longitud > 180 ||
        this.registrobio.reg_longitud < -180 ||
        this.registrobio.reg_longitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Longitud válido ej: -74.118597 separando decimales con punto (.) ";
      } else if (
        this.registrobio.reg_asnm === null ||
        !this.global.validarNumeroEntero(this.registrobio.reg_asnm) ||
        this.registrobio.reg_asnm < 0 ||
        this.registrobio.reg_asnm > 10000 ||
        this.registrobio.reg_asnm === ""
      ) {
        mensajeAlerta = "Debe digitar una ASNM válida";
      } else if (this.registrobio.reg_tipoobservacion === null) {
        mensajeAlerta = " Debe seleccionar un tipo de observación ";
      } else if (this.registrobio.reg_observaciones.length > 955) {
        mensajeAlerta = "Debe digitar observaciones no mayor a 955 caracteres";
      } else if (
        !moment(this.registrobio.reg_fecha, "YYYY/MM/DD", true).isValid()
      ) {
        mensajeAlerta = "Debe digitar una fecha válida";
      } else if (
        !moment(this.registrobio.reg_hora, "HH:MM:SS", true).isValid()
      ) {
        mensajeAlerta = "Debe digitar una hora válida";
      }

      return mensajeAlerta;
    },
  },

  created: function () {
    this.getespecies();
    this.getfincas();
    this.getfincascomplete();
    this.gettiposobservaciones();
    this.getcoberturas();

    this.getanios();
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.contenedor {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 25% 75%;
  background-color: #f2f2f2;
}

.filter-regbio {
  width: 95%;
  height: 100vh;
  margin: 1rem auto;
  background-color: #fff;
  padding: 10px 1rem 0 1rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
}

.result-query-bio {
  /* padding: 10px; */
  /* gap: 10px; */
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.result-query-bio:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}

.tittle{
  font-weight: 700;
}

.details-registro{
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 0 0 10px 0;
}

.panel-bio {
  margin: 1rem auto;
}

.graph {
  height: 100%;
}
.result-data {
  /* padding: 1rem; */
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

.result-multimedia {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
  border-radius: 8px 8px 0 0;
}

.gallery {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  width: 95%;
  height: 300px;
  margin: 1rem auto;
}

.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
}

.uno {
  margin-right: 20px;
  flex-grow: 10;
}

.dos {
  margin-left: 20px;
  flex-grow: 5;
}

.tres {
  flex-grow: 5;
}

.article {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  /* display: grid;
  place-content: center; */
}

.button-trash-gallery {
  display: flex;
}

.multimedia {
  width: 250px;
  height: 250px;
}
</style>
